var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-section text-no-wrap",attrs:{"headers":_vm.columns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"header-props":_vm.headerprops},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.fiat.active ? 'success' : ''}},[_vm._v(" "+_vm._s(item.fiat.active ? 'ACTIVE' : 'INACTIVE')+" ")]),(item.fiat.base)?_c('v-chip',{attrs:{"color":"info"}},[_vm._v(" BASE ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name.en)+" ")]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" - "+_vm._s(item.fiat.active ? 'ACTIVE' : '(INACTIVE)')+" "+_vm._s(item.fiat.base ? '(BASE)' : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.resource = item}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[(_vm.resource)?[(_vm.$can('country:update', 'country_management'))?_c('edit-dialog',{attrs:{"resource":_vm.resource},on:{"updated":_vm.paginate}}):_vm._e()]:_c('v-list-item',{staticStyle:{"min-width":"100px"}})],2)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }